







import {Component, Mixins, Watch} from 'vue-property-decorator';
import Feed from '@/components/Feed.vue';
import PostInterface from '@/types/PostInterface';
import LoaderBottom from '@/components/LoaderBottom.vue';
import {vxm} from '@/store';
import FeedMixin from '@/mixins/FeedMixin';

@Component({
  components: {Feed, LoaderBottom},
})
export default class Video extends Mixins(FeedMixin) {
  feed = [] as PostInterface[];
  loading = true;
  searchFlag = false;
  @Watch('$route.params.token')
  doSearch() {
    if (this.$route.name !== 'video') {
      this.searchFlag = true;
    } else {
      this.videoSearch();
    }
  }
  @Watch('$route.name')
  watchRoute() {
    if (this.$route.name === 'video' && this.searchFlag) {
      this.videoSearch();
      this.searchFlag = false;
    }
  }
  created() {
    this.videoSearch();
  }
  videoSearch() {
    const searchToken = this.$route.params.token;
    if (searchToken) {
      vxm.search
        .doSearch({search: searchToken, type: 'video', page: 1, limit: 20})
        .then((res: any) => {
          const data = res.data.data;
          this.feed = data.posts && data.posts.length ? data.posts : [];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
